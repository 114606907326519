import { Directive } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[preventClick]',
    host: {
        '(click)': 'preventClick($event)'
    }
})
export class PreventClickDirective {
    preventClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
