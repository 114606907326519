import { Component, ElementRef, ViewChild, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import * as opentype from 'opentype.js';
import { FontFamily, NewFontFamilyDto, NewFontStyleDto } from '../../models/fontFamily.model';
import { FontService } from '../../services/font-manager.service';
import { DragDirective } from '../../directive/drag-drop.directive';

export type FontNamesExtension = opentype.FontNames & {
    preferredFamily?: { [key in string]: string };
    preferredSubfamily?: { [key in string]: string };
};
export type EventFiles = { target: { files: FileList[] } };
@Component({
    standalone: true,
    imports: [CommonModule, UIModule, DragDirective],
    selector: 'font-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
    @ViewChild('fileUpload', { static: true })
    fileUpload: ElementRef;

    @Input() selectedFontFamily: FontFamily;
    @Input() hasDragDrop = false;

    private numberOfFilesAdded: number;
    private newFontFamilies: NewFontFamilyDto[] = [];
    private existingFontFamilies: NewFontFamilyDto[] = [];
    private hasError: boolean;

    fontService = inject(FontService);
    private uiNotificationService = inject(UINotificationService);

    addFiles(event: EventFiles | never): void {
        this.hasError = false;
        let fileList: FileList[] = [];

        if (event.target && event.target.files) {
            fileList = event.target.files;
        } else {
            fileList = event as unknown as FileList[];
        }

        if (fileList.length) {
            this.numberOfFilesAdded = fileList.length;

            for (const file of fileList) {
                const newFontStyle: NewFontStyleDto = new NewFontStyleDto();
                this.validateFile(
                    newFontStyle,
                    file as unknown as Blob & {
                        name: string;
                    }
                );
            }

            this.fileUpload.nativeElement.value = ''; // Reset the file upload input field.
        }
    }

    validateFile(newFontStyle: NewFontStyleDto, file: Blob & { name: string }): void {
        const reader: FileReader = new FileReader();
        reader.readAsArrayBuffer(file);

        // Parsing of file succeeded
        reader.onloadend = (): void => {
            try {
                const bitArray: Uint8Array = new Uint8Array(reader.result as ArrayBuffer);
                const headerHexArray: Uint8Array = bitArray.subarray(0, 4);
                let headerHex = '';

                headerHexArray.forEach(item => {
                    headerHex += item.toString(16);
                });

                // Hex code for Woff, TTF and OTF files.
                if (headerHex === '774f4646' || headerHex === '0100' || headerHex === '4f54544f') {
                    const metaData: opentype.Font = opentype.parse(reader.result); // Generate the meta data

                    // First, generate a Base64 for the font file.
                    newFontStyle.fontFile = btoa(
                        Array.from(bitArray)
                            .map(item => String.fromCharCode(item))
                            .join('')
                    );

                    newFontStyle.weight = metaData.tables.os2.usWeightClass
                        ? Math.ceil(metaData.tables.os2.usWeightClass / 100) * 100
                        : 400;

                    // Make sure the font weights are not out of range.
                    if (newFontStyle.weight > 900) {
                        newFontStyle.weight = 900;
                    } else if (newFontStyle.weight < 100) {
                        newFontStyle.weight = 100;
                    }

                    newFontStyle.italic = !!metaData.tables.post.italicAngle;
                    newFontStyle.uploadedFileName = file.name;

                    // Font Style Names
                    const fontNames: FontNamesExtension = metaData.names;
                    const fontFamilyName = this.setupFontNames(newFontStyle, fontNames);

                    // Glyphs are in object form, so we need to create an array with the glyphs unicode
                    // tslint:disable-next-line: no-any
                    const glyphs: any = metaData.glyphs;
                    for (const key in glyphs.glyphs) {
                        if (glyphs.glyphs[key].unicode) {
                            newFontStyle.unicodeGlyphs.push(glyphs.glyphs[key].unicode);
                        }
                    }

                    this.handleFontFamilyLogic(newFontStyle, fontFamilyName);

                    this.numberOfFilesAdded--;

                    // When numberOfFilesAdded reaches 0, there is no more files to parse and we should go ahead and create the fontfamily.
                    if (this.numberOfFilesAdded === 0) {
                        this.createFontFamily();
                    }
                } else if (headerHex === '774f4632') {
                    // This is a Woff2 file.
                    this.numberOfFilesAdded--;
                    this.displayError(
                        'The Font Manager currently do not support woff2 files.<br/> Please try again with another file.'
                    );
                } else {
                    this.numberOfFilesAdded--;
                    this.displayError(
                        'A file you uploaded is not a valid font file.<br/> Please try again with another file.'
                    );
                }
            } catch (error) {
                this.displayError();
                // tslint:disable-next-line: no-console
                console.error(error);
            }
        };

        reader.onerror = (): void => {
            this.displayError();
        };
    }

    trim(stringToBeTrimmed: string): string {
        stringToBeTrimmed = stringToBeTrimmed.replace(/^[\n\s]+/, '');
        const trimmedString = stringToBeTrimmed.replace(/[\n\s]+$/, '');

        return trimmedString;
    }

    private getFirstValueIn(obj: {
        [key in string]: string;
    }): string {
        return obj[Object.keys(obj)[0]] as string;
    }

    // Setup font Family Name
    private setupFontNames(newFontStyle: NewFontStyleDto, fontNames: FontNamesExtension): string {
        let fontFamilyName = '';

        // Setup font family name, and checking what type of metadata is available
        try {
            if (
                fontNames.preferredFamily &&
                this.trim(this.getFirstValueIn(fontNames.preferredFamily))
            ) {
                fontFamilyName = this.getFirstValueIn(fontNames.preferredFamily);
            } else if (fontNames.fontFamily && this.trim(this.getFirstValueIn(fontNames.fontFamily))) {
                fontFamilyName = this.getFirstValueIn(fontNames.fontFamily);
            } else if (fontNames.fullName && this.trim(this.getFirstValueIn(fontNames.fullName))) {
                fontFamilyName = this.getFirstValueIn(fontNames.fullName);
            } else {
                fontFamilyName = newFontStyle.uploadedFileName
                    ? newFontStyle.uploadedFileName.split('.')[0]
                    : 'Undefined';
            }
        } catch (error) {
            // tslint:disable-next-line: no-console
            console.error('Something went wrong trying to extract the font family name', error);
            fontFamilyName = 'Undefined';
        }

        // Setup style name
        try {
            if (
                fontNames.preferredSubfamily &&
                this.trim(this.getFirstValueIn(fontNames.preferredSubfamily))
            ) {
                newFontStyle.name = this.getFirstValueIn(fontNames.preferredSubfamily);
            } else if (
                fontNames.fontSubfamily &&
                this.trim(this.getFirstValueIn(fontNames.fontSubfamily))
            ) {
                newFontStyle.name = this.getFirstValueIn(fontNames.fontSubfamily);
            } else if (newFontStyle.weight) {
                newFontStyle.name = newFontStyle.weight.toString();
            } else {
                newFontStyle.name = 'Regular';
            }
        } catch (error) {
            // tslint:disable-next-line: no-console
            console.error('Something went wrong trying to fetch the font style name', error);
            newFontStyle.name = 'Regular';
        }

        return fontFamilyName;
    }

    // Will determine if a font belongs to a font family, or if its a completely new font family.
    private handleFontFamilyLogic(newFontStyle: NewFontStyleDto, fontFamilyName: string): void {
        const hasExistingFontFamily: FontFamily | undefined = this.fontService.fontFamilies.find(
            (fontFamily: FontFamily) => fontFamily.name === fontFamilyName && !fontFamily.isAccountFont
        );

        // Have the user uploaded a font style that is connected to a font family that already exists?
        if (hasExistingFontFamily || this.selectedFontFamily) {
            const existsInExistingFontFamilies: NewFontFamilyDto | undefined =
                this.existingFontFamilies.find(
                    (fontFamily: NewFontFamilyDto) => fontFamily.name === fontFamilyName
                );

            // Are there more than one font style that is connected to an existing font family?
            if (existsInExistingFontFamilies) {
                existsInExistingFontFamilies.fontStyles.push(newFontStyle);
            } else {
                const existingFontFamilyDto: NewFontFamilyDto = new NewFontFamilyDto();

                if (this.selectedFontFamily) {
                    existingFontFamilyDto.id = this.selectedFontFamily.id;
                    existingFontFamilyDto.name = this.selectedFontFamily.name;
                } else {
                    existingFontFamilyDto.id = hasExistingFontFamily!.id;
                    existingFontFamilyDto.name = hasExistingFontFamily!.name;
                }

                existingFontFamilyDto.fontStyles.push(newFontStyle);
                this.existingFontFamilies.push(existingFontFamilyDto);
            }
        } else {
            const existsInNewFontFamilies: NewFontFamilyDto | undefined = this.newFontFamilies.find(
                (fontFamily: NewFontFamilyDto) => fontFamily.name === fontFamilyName
            );

            if (existsInNewFontFamilies) {
                existsInNewFontFamilies.fontStyles.push(newFontStyle);
            } else {
                const newFontFamily: NewFontFamilyDto = new NewFontFamilyDto();
                newFontFamily.name = fontFamilyName;
                newFontFamily.brandId = this.fontService.brandId;
                newFontFamily.fontStyles.push(newFontStyle);

                this.newFontFamilies.push(newFontFamily);
            }
        }
    }

    /*
     *   Create the DTO object to send to the server.
     */
    async createFontFamily(): Promise<void> {
        let mutatedFontFamily: FontFamily | undefined = new FontFamily();
        for (const newFontFamily of this.newFontFamilies) {
            const resolvedFamily: FontFamily | void =
                await this.fontService.createFontFamily(newFontFamily);

            if (!mutatedFontFamily.id && resolvedFamily) {
                mutatedFontFamily = resolvedFamily;
            }
        }

        for (const existingFontFamily of this.existingFontFamilies) {
            await this.fontService.addFontStyles(existingFontFamily.id, existingFontFamily.fontStyles);
            mutatedFontFamily = this.fontService.fontFamilies.find(
                fontFamily => fontFamily.id === existingFontFamily.id
            );
        }
        // When all uploads are complete, notify subscribers...
        this.fontService.afterFilesUploaded.next(mutatedFontFamily);

        this.displaySuccess();

        // Reset the uploads
        this.newFontFamilies = [];
        this.existingFontFamilies = [];
    }

    private displaySuccess(): void {
        if (!this.hasError && !this.fontService.hasError) {
            this.uiNotificationService.open('Font uploaded successfully!', {
                type: 'success',
                placement: 'top',
                autoCloseDelay: 3000
            });
        }
    }

    private displayError(errorText?: string): void {
        this.hasError = true;
        this.fileUpload.nativeElement.value = '';

        this.fontService.displayError(
            undefined,
            errorText
                ? errorText
                : 'Something went wrong when trying to upload the file. Please try again.'
        );
    }
}
