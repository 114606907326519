<div class="topbar-name">
    {{ selectedFontFamily?.name }}
</div>
<div class="topbar-content">
    <div class="topbar-controls">
        @if (!isLoading() && selectedFontFamily) {
            <font-style-header
                [fontFamily]="selectedFontFamily"
                (selectionChange)="onChangeDisplayType($event)" />
        }
    </div>

    @if (!isLoading() && selectedFontFamily) {
        <div class="topbar-controls">
            <ui-input
                #customInput
                class="input"
                [value]="customText()"
                (valueChange)="onCustomTextChange($event)"
                [maxlength]="300"
                autocomplete="off"
                placeholder="Custom preview" />
        </div>
    }
</div>
@if (fontFamilies.length) {
    <div class="topbar-buttons">
        @if (fontStyles.length && isAllowedToModifyFont(selectedFontFamily)) {
            <ui-button
                icon="trash"
                [text]="'Delete (' + fontStyles.length + ')'"
                (click)="onConfirmStylesDeletion()"
                (keypress)="onConfirmStylesDeletion()" />
        }

        @if (!fontStyles.length) {
            <font-upload
                [(selectedFontFamily)]="selectedFontFamily"
                [hasDragDrop]="false" />
        }

        @if (isAllowedToModifyFont(selectedFontFamily)) {
            <ui-svg-icon
                class="settings-icon"
                icon="settings"
                (click)="onToggleSettings()"
                (keypress)="onToggleSettings()" />
        }
    </div>
}
