@if (isAuthenticating()) {
    <ui-loader />
} @else {
    @if (!isAuthenticated()) {
        <div class="wrapper">
            <ui-logo [small]="true"></ui-logo>

            <span> To access Font Manager, please login with your credentials </span>
            <ui-button
                text="Login"
                type="primary"
                (click)="login()" />
        </div>
    }
}
