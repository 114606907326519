export class Brand {
    id: string;
    name: string;
    logoUri: string | null;

    constructor(json: IBrandJSON) {
        this.id = json.id;
        this.name = json.name;
        this.logoUri = json.logoUri;
    }
}

export interface IBrandJSON {
    id: string;
    name: string;
    logoUri: string | null;
}

export const mockData: Brand[] = [
    {
        id: '5559f4add83ca310c827f3c8',
        name: 'FlowerKing',
        logoUri: null
    },
    {
        id: '5da95e8157397a0b6813c6ae',
        name: 'FlowerQueen',
        logoUri: null
    },
    {
        id: '5da95e8157397a0b6813c6rr',
        name: 'superBrand',
        logoUri: null
    },
    {
        id: '5da95e8157397a0b6813c666',
        name: 'formaggioKebab',
        logoUri: null
    },
    {
        id: '5da95e8157397a0b6813c677',
        name: 'lethalCoffee',
        logoUri: null
    }
];
