import { Component, effect, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { AuthService } from 'src/services/auth.service';

@Component({
    standalone: true,
    imports: [UIModule],
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
    private activatedRoute = inject(ActivatedRoute);
    private authService = inject(AuthService);
    private router = inject(Router);

    isAuthenticated = this.authService.isAuthenticated;

    isAuthenticating = signal(false);

    constructor() {
        effect(() => {
            if (this.isAuthenticated()) {
                this.navigateToFontManager();
            }
        });
    }

    login(): void {
        this.isAuthenticating.set(true);
        this.authService.loginWithPopup().subscribe(() => {
            this.navigateToFontManager();
        });
    }

    private navigateToFontManager(): void {
        const brandId = this.activatedRoute.snapshot.paramMap.get('brand-id');
        if (!brandId) {
            throw new Error('No brand id');
        }
        this.router.navigateByUrl('/in-frame/' + brandId);

        this.isAuthenticating.set(false);
    }
}
