@if (!brandId) {
    <div style="color: red; padding: 4rem">ERROR: Brand ID not defined.</div>
} @else {
    <div class="flex-container ui-scrollbar">
        <div
            class="flex-container header"
            ui-theme="minimal">
            <div class="flex-item headercontent-left">
                <font-upload [hasDragDrop]="true" />

                <ui-button
                    id="open-google-fonts-dialog"
                    class="import-fonts-btn"
                    (click)="openFontImportDialog()"
                    (keypress)="openFontImportDialog()"
                    text="Import"
                    svgIcon="cloud-no-arrow-s-default"
                    [uiTooltip]="'Import from Google Fonts'"
                    uiTooltipPosition="bottom" />
            </div>
            <div class="flex-item headercontent-right">
                <font-manager-topbar
                    [selectedFontFamily]="selectedFontFamily"
                    [isLoading]="isLoading"
                    [customText]="customText"
                    (changeDisplayType)="changeDisplayType($event)"
                    (confirmStylesDeletion)="confirmStylesDeletion()"
                    (toggleSettings)="toggleSettings()" />
            </div>
        </div>
        <div class="main-panel sidebar ui-scrollbar">
            <div class="font-family-header">
                <div class="font-family-header-text">
                    <ui-icon icon="text" />
                    Installed on this brand
                </div>
            </div>
            @for (fontFamily of getInstalledOnThisBrandFonts(); track fontFamily.id) {
                @if (!isLoading && !fontFamily) {
                    <div class="font-family-item">
                        <div class="font-family-item-text">
                            <i>No fonts found</i>
                        </div>
                    </div>
                }

                @if (fontFamily) {
                    <div class="font-family-item">
                        <font-family
                            [fontFamily]="fontFamily"
                            [isSelectedFontFamily]="selectedFontFamily.id === fontFamily.id"
                            (selectFontFamily)="selectFontFamily($event, false)"
                            (openSettings)="openSettings($event)"
                            (confirmMergeFontFamily)="confirmMergeFontFamily($event)"
                            (addFontStyles)="setup()" />
                    </div>
                }
            }

            @if (!isLoading && isAdmin()) {
                <div class="font-family-header font-family-extra-border">
                    <div class="font-family-header-text"><ui-icon icon="text" /> Not on this brand</div>
                </div>
            }

            @for (fontFamily of getNotOnThisBrandFonts(); track fontFamily.id) {
                @if (fontFamily) {
                    <div class="font-family-item">
                        <font-family
                            [fontFamily]="fontFamily"
                            [isSelectedFontFamily]="selectedFontFamily.id === fontFamily.id"
                            (selectFontFamily)="selectFontFamily($event, false)"
                            (openSettings)="openSettings($event)"
                            (confirmMergeFontFamily)="confirmMergeFontFamily($event)"
                            (addFontStyles)="setup()" />
                    </div>
                }
            }
        </div>
        <div class="main-panel main ui-scrollbar">
            @if (showSettings) {
                <div @openClose>
                    <font-settings
                        #settingsComponent
                        [selectedFontFamily]="selectedFontFamily"
                        (toggleSettings)="toggleSettings($event)" />
                </div>
            }

            @if (selectedFontFamily) {
                <font-styles
                    #fontStyles
                    [(customText)]="customText"
                    (deleteFontStyle)="deleteFontStyle($event)"
                    [fontFamily]="selectedFontFamily"
                    [displayType]="displayType" />
            }
            @if (!isLoading && !selectedFontFamily?.fontStyles?.length) {
                <div class="main-nothing-found">
                    <i>No font styles found</i>
                </div>
            }
        </div>
    </div>
}
