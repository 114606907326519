import { Routes } from '@angular/router';
import { FontManagerComponent } from 'src/components';
import { AuthComponent } from 'src/components/auth/auth.component';
import { canActivateGuard } from './auth.guard';
import { FontResolver } from './font.resolver';
import { InDialogComponent } from './in-dialog.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/in-dialog',
        pathMatch: 'full'
    },
    {
        path: 'in-dialog',
        component: InDialogComponent
    },
    {
        path: 'auth/:brand-id',
        component: AuthComponent
    },
    {
        path: 'in-frame/:brand-id',
        component: FontManagerComponent,
        resolve: { data: FontResolver },
        canActivate: [canActivateGuard]
    }
];
