@let fontFamilyValue = fontFamily();
<div
    class="font-family-item"
    [class.active]="isSelectedFontFamily()"
    (click)="select()"
    [uiTooltip]="
        fontFamilyValue.isAccountFont && !isAdmin()
            ? 'This font family is shared between multiple brands. Only admin users can edit this family'
            : fontFamily.name
    ">
    <div class="font-family-data">
        <ui-svg-icon
            class="font-icon"
            [icon]="fontFamilyValue.isAccountFont ? 'folder-shared' : 'folder'" />
        <div class="item">
            <div class="name">{{ fontFamilyValue.name }}</div>
            <div class="number">({{ fontFamilyValue.fontStyles.length }})</div>
        </div>

        <div class="menu-container">
            @if (isAllowedToModifyFont(fontFamilyValue)) {
                <button
                    class="menu"
                    [uiDropdownTarget]="fontActions"
                    preventClick>
                    <ui-icon icon="more-standing" />
                </button>
            }
            <ui-dropdown
                class="font-actions"
                #fontActions>
                <!-- On all font families -->
                <ui-dropdown-item
                    svgIcon="folder-move"
                    class="move"
                    [uiDropdownTarget]="mergeMenu.uiDropdown">
                    Merge to family
                </ui-dropdown-item>

                <ui-dropdown-item
                    svgIcon="settings"
                    class="settings"
                    (click)="settings()">
                    Settings
                </ui-dropdown-item>

                <!-- Merge to family submenu list -->
                <move-merge-menu
                    #mergeMenu
                    [fontFamily]="fontFamilyValue"
                    mergeOrMove="merge"
                    (menuChoiceClicked)="mergeFontFamily($event)">
                </move-merge-menu>
            </ui-dropdown>
        </div>
    </div>
</div>
