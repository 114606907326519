import { IAppEnvironment } from 'src/domain/environment.type';

export const environment: IAppEnvironment = {
    stage: 'sandbox',
    production: true,
    clientRoot: 'https://sandbox-fontmanager.bannerflow.com/',
    gtmId: 'GTM-M2FZ5ZZ',
    newRelic: {
        enabled: true,
        accountId: '4232543',
        trustKey: '4122654',
        agentId: '538562727',
        licenseKey: 'NRJS-d0b27a9b958bc4b281c',
        applicationId: '538562727'
    },
    auth0: {
        clientId: 'YOuOfOnjGc8x4QmX6yVJm2878CI85UWI'
    },
    origins: {
        auth0Login: 'https://sandbox-login.bannerflow.com',
        fontManagerApi: 'https://sandbox-api.bannerflow.net/font-manager/api'
    }
};
